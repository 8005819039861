import PropTypes from "prop-types"
import React from "react"

const Header = ({ siteTitle }) => (
  <header
    className='header'
  >
    <div className="header-inner container clear">
      <a className="logo" href="#"><span className="sr">{ siteTitle }</span></a>
      <input
        type="checkbox"
        id="navigation-toggle-checkbox"
        name="navigation-toggle-checkbox"
        className="navigation-toggle-checkbox none"
      />
        <label htmlFor="navigation-toggle-checkbox" className="navigation-toggle-label">
          <span className="navigation-toggle-label-inner">
            <span className="sr">Navigation</span>
          </span>
        </label>
        <div className="navigation">
          <ul className="navigation-menu">
            <li className="navigation-item"><a href="#home">Home</a></li>
            <li className="navigation-item"><a href="#services">Services</a></li>
            <li className="navigation-item"><a href="#about">About Us</a></li>
            <li className="navigation-item"><a href="#contact">Contact</a></li>
          </ul>
        </div>
    </div>
  </header>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
