import React from 'react';

const Solution = () => (
  <div className="container">
    <div className="banner-inner">
      <h1 className="banner-lead">
        <span className="banner-lead-1">Technology Solutions.</span>
      </h1>
      <div className="banner-content">
        <p>
          Wielding technology to solve real problems.
        </p>
      </div>
      <div className="banner-buttons">
        <a href="#services" className="button button-primary">Get started</a>
        <a href="#about" className="button button-secondary" style={ { color: '#d6c6c6' } }>Learn more</a>
      </div>
    </div>
  </div>
);

export default Solution;
