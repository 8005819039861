/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import PropTypes from "prop-types"

import Header from "./header"
import Solution from "./sections/Solution"
import { getImgUrl } from "../utils"

const Layout = ({ children }) => {
  const siteTitle = 'Solmation'
  return (
    <>
      <div className='banner'>
        <img data-src={ getImgUrl("/img/banne2r.jpg") } alt="banner" src-set={ getImgUrl('/img/banner-small.jpg') } className='bg-image lazyload'/>
        <Header siteTitle={ siteTitle } />
        <Solution />
        <img data-src={ getImgUrl('/img/banner.svg') } alt='wave' className='banner-wave lazyload' />
      </div>
      <main>{children}</main>
      <footer className='footer'>
        <div className="footer-inner container">
          <div className="clear">
            <div className="footer-column col-tablet-8">
              <p>
                &copy; Copyright 2015 &mdash; All Rights Reserved
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
